import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import Content from "../Common/Content"
import Wrapper from "../Common/Wrapper"

import { WorksContainer, WorkItem, WorkImage} from "./styled"


const Works= () => (
    <>
        <Wrapper>
            <Content>
                <h1>Work</h1>
                <WorksContainer>
                  <List class="root">
                    {/* Flex */}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Flex Logo" src={require('../../images/works/flex-logo.jpg')} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Senior Software Engineer (Frontend)"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              class="inline"
                              color="textPrimary"
                            >
                              Flex (Flexible Finance)   |   2022 - Present
                            </Typography>
                            <br/>
                            {"I deliver features for a consumer-facing React Native mobile app and React web app, focused on enhancing mobile user experience. Led web/mobile app store releases and front-end feature development for the core user experience of a financial startup’s rent payment mobile and web apps."}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />

                    {/* Pariveda Solutions */}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Pariveda Solutions Logo" src={require('../../images/works/pariveda-logo.jpg')} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Associate (Senior Technology Consultant)"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              class="inline"
                              color="textPrimary"
                            >
                              Pariveda Solutions   |   2018 - 2022
                            </Typography>
                            <br/>
                            {"Solved clients’ most ambiguous problems using technology and rapid, iterative development techniques. Previous projects include cross-platform mobile development, back-end application development, data migration, machine learning pipelines, and full-stack web development."}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />

                    {/* iD Tech */}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="iD Tech Logo" src={require('../../images/works/id-logo.jpg')} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Instructor & Tech Coordinator"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              class="inline"
                              color="textPrimary"
                            >
                              iD Tech   |   2016 - 2018
                            </Typography>
                            <br/>
                            {"Taught Machine Learning and Programming courses for students aged 13 -­ 18. During my time at iD Tech I oversaw production of 100+ projects, prepared course curriculum, and provided technical support for all camp computers."}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />

                    {/* Principal Financial Group */}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Principal Financial Group Logo" src={require('../../images/works/principal-logo.jpg')} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Application Development Intern"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              class="inline"
                              color="textPrimary"
                            >
                              Principal Financial Group | 2017
                            </Typography>
                            <br/>
                            {"Developed internal web applications required for day-to-day financial auditing utilizing Java, Javascript, and HTML. Led my team to delivery of this application as an Agile Team Coach. Additionally, I developed test suites which brought code coverage of an existing application from 9% to 90%."}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />


                    {/* RPI */}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Rensselaer Polytechnic Institute Logo" src={require('../../images/works/rpi-logo.jpg')} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Administrative Assistant"
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              class="inline"
                              color="textPrimary"
                            >
                              Rensselaer Polytechnic Institute | 2015 - 2018
                            </Typography>
                            <br/>
                            {"Performed administrative and organizational services for Professors of the Cognitive Science Department at RPI, providing professors and research groups with the resources they require."}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                </WorksContainer>

            </Content>
        </Wrapper>
    </>
)

const WorkItems = () => {
    const data = useStaticQuery(graphql`
    {
        allFile(filter: {relativeDirectory: {eq: "works"}}) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `)

    return (
        <WorkItem>
          {data.allFile.edges.map((image, key) => (
                <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">
                    <WorkImage
                        key={key}
                        fluid={image.node.childImageSharp.fluid}
                    />
                </a>
            ))}
        </WorkItem>
      )

}

export default Works
